export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

export const GET_USER = "GET_USER";
export const USER_ERROR = "USER_ERROR";
export const NOT_FOUND_ERROR = "NOT_FOUND_ERROR";

export const FAMILY_LOADING = "FAMILY_LOADING";
export const GET_FAMILY = "GET_FAMILY";
export const CLEAR_FAMILY = "CLEAR_FAMILY";
export const UPDATE_FAMILY = "UPDATE_FAMILY";
export const IS_PRIVATE_TREE = "IS_PRIVATE_TREE";
export const CLEAR_IS_PRIVATE_TREE = "CLEAR_IS_PRIVATE_TREE";
export const FAMILY_ERROR = "FAMILY_ERROR";
export const GET_RELATIONSHIP = "GET_RELATIONSHIP";
export const GET_RELATIONSHIP_FAILURE = "GET_RELATIONSHIP_FAILURE";
export const APPEND_RELATIONSHIP = "APPEND_RELATIONSHIP";
export const RELATIONSHIP_ERROR = "RELATIONSHIP_ERROR";
export const RENDER_STEPPER = "RENDER_STEPPER";
export const RENDER_TREE = "RENDER_TREE";
export const SET_NEW_FAMILY_PAYLOAD = "SET_NEW_FAMILY_PAYLOAD";
export const SET_HINTS_PAYLOAD = "SET_HINTS_PAYLOAD";
export const UPDATE_HINT_COUNT_ITEM = "UPDATE_HINT_COUNT_ITEM";
export const UPDATE_HINT_COUNT_ITEMS = "UPDATE_HINT_COUNT_ITEMS";
export const SET_MATCHES = "SET_MATCHES";
export const GET_EDITED_FAMILY = "GET_EDITED_FAMILY";
export const UPDATE_USER_PREFERENCES = "UPDATE_USER_PREFERENCES";
export const SET_HOMEPERSON_MODAL = "SET_HOMEPERSON_MODAL";
export const SET_HOMEPERSON = "SET_HOMEPERSON";
export const CLOSE_HOMEPERSON_MODAL = "CLOSE_HOMEPERSON_MODAL";
export const CLEAR_REFETCH_TREE_FLAG = " CLEAR_REFETCH_TREE_FLAG";
export const SHOW_ADD_GRANDPARENT_MODAL = "SHOW_ADD_GRANDPARENT_MODAL";
export const CLOSE_ADD_GRANDPARENT_MODAL = "CLOSE_ADD_GRANDPARENT_MODAL";
export const RESTRICT_FIRST_HINT_HIGHLIGHT = "RESTRICT_FIRST_HINT_HIGHLIGHT";

export const ADD_PARENT = "ADD_PARENT";
export const ADD_SIBLING = "ADD_SIBLING";

export const SAVE_PARENT = "SAVE_PARENT";
export const SAVE_SIBLING = "SAVE_SIBLING";
export const ADD_SPOUSE = "ADD_SPOUSE";
export const SAVE_SPOUSE = "SAVE_SPOUSE";
export const SAVE_ERROR = "SAVE_ERROR";

export const ADD_CHILD = "ADD_CHILD";
export const SAVE_CHILD = "SAVE_CHILD";

export const ADD_FAMILY = "ADD_FAMILY";
export const CANCEL_MODAL = "CANCEL_MODAL";

export const SAVE_PARENTS = "SAVE_PARENTS";

export const GET_EDIT_PERSON = "GET_EDIT_PERSON";
export const ADD_TREE = "ADD_TREE";
export const TREE_ERROR = "TREE_ERROR";
export const RENDER_NEW_TREE = "RENDER_NEW_TREE";

export const ADD_PARENT_VIA_PLACEHOLDER = "ADD_PARENT_VIA_PLACEHOLDER";
export const SAVE_PARENT_VIA_PLACEHOLDER = "SAVE_PARENT_VIA_PLACEHOLDER";
export const SELECT_USER = "SELECT_USER";
export const GET_TREES = "GET_TREES";
export const GET_ALL_TREES = "GET_ALL_TREES";
export const SET_ALL_USER_TREES = "SET_ALL_USER_TREES";
export const TREES_LOADING = "TREES_LOADING";
export const TREE_PERSON_SEARCH_OPTIONS = "TREE_PERSON_SEARCH_OPTIONS";

export const UPDATE_SELECTED_PARENT_SIDE = "UPDATE_SELECTED_PARENT_SIDE";

export const PERSON_PAGE_LOADING = "PERSON_PAGE_LOADING";
export const REFETCHED = "REFETCHED";
export const WHOLE_REFETCHED = "WHOLE_REFETCHED";
export const PARTIAL_REFETCHED = "PARTIAL_REFETCHED";
export const FETCH_PARENTS = "FETCH_PARENTS";
export const UPDATE_PARENTS = "UPDATE_PARENTS";
export const FETCH_SPOUSES = "FETCH_SPOUSES";
export const UPDATE_SPOUSES = "UPDATE_SPOUSES";
export const PERSON_LOADING = "PERSON_LOADING";
export const GET_PERSON = "GET_PERSON";

export const GET_PERSONAL_INFO = "GET_PERSONAL_INFO";
export const GET_EVENTS = "GET_EVENTS";
export const GET_SPOUSES = "GET_SPOUSES";
export const GET_PARENTS = "GET_PARENTS";
export const GET_LIFEEVENTS = "GET_LIFEEVENTS";
export const ADD_LIFEEVENT = "ADD_LIFEEVENT";
export const SUCCESS_LIFEEVENT = "SUCCESS_LIFEEVENT";
export const FAIL_LIFEEVENT = "FAIL_LIFEEVENT";

export const UPDATE_PERSON = "UPDATE_PERSON";
export const UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO";
export const UPDATE_PERSONINFO_ONLY = "UPDATE_PERSONINFO_ONLY";
export const UPDATE_SPOUSES_AND_CHILDREN = "UPDATE_SPOUSES_AND_CHILDREN";
export const UPDATE_PARENTS_AND_SIBLINGS = "UPDATE_PARENTS_AND_SIBLINGS";
export const UPDATE_EVENTS = "UPDATE_EVENTS";
export const UPDATE_LIFE_EVENTS = "UPDATE_LIFE_EVENTS";
export const PERSON_ERROR = "PERSON_ERROR";
export const ADD_RELATED_EVENT = "ADD_RELATED_EVENT";
export const SET_EVENT_MODAL_DATA = "SET_EVENT_MODAL_DATA";

export const IMPORT_GEDCOM = "IMPORT_GEDCOM";
export const PROGRESS_BAR = "PROGRESS_BAR";
export const GEDCOM_ERROR = "GEDCOM_ERROR";
export const IS_GEDCOM_UPLOADED = "IS_GEDCOM_UPLOADED";
export const SET_IS_GEDCOM_UPLOADED = "SET_IS_GEDCOM_UPLOADED";
export const INVALID_GEDCOM_FILE = "INVALID_GEDCOM_FILE";

export const SHOW_TREE_CELEBRATORY_MODAL = "SHOW_TREE_PROGRESS_MODAL";
export const CLOSE_TREE_PROGRESS_MODAL = "CLOSE_TREE_PROGRESS_MODAL";

export const GET_IMPORT_STATUS = "GET_IMPORT_STATUS";
export const IMPORT_STATUS_ERROR = "IMPORT_STATUS_ERROR";

export const ASSIGN_HOMEPERSON = "ASSIGN_HOMEPERSON";
export const IMPORT_IN_PROGRESS = "IMPORT_IN_PROGRESS";
export const ASSIGN_ERROR = "ASSIGN_ERROR";

export const SELECT_HOMEPERSON = "SELECT_HOMEPERSON";
export const SELECT_HOMEPERSON_ERROR = "SELECT_HOMEPERSON_ERROR";

export const AUTOCOMPLETE_REQUEST = "AUTOCOMPLETE_REQUEST";
export const AUTOCOMPLETE_TEST = "AUTOCOMPLETE_TEST";
export const AUTOCOMPLETE_ERROR = "AUTOCOMPLETE_ERROR";

export const AUTOCOMPLETE_PAGINATION_REQUEST = "AUTOCOMPLETE_PAGINATION_REQUEST";
export const AUTOCOMPLETE_PAGINATION_TEST = "AUTOCOMPLETE_PAGINATION_TEST";
export const AUTOCOMPLETE_PAGINATION_ERROR = "AUTOCOMPLETE_PAGINATION_ERROR";

export const AUTOCOMPLETE_BIRTH_TEST = "AUTOCOMPLETE_BIRTH_TEST";
export const AUTOCOMPLETE_BIRTH_PAGINATION_TEST = "AUTOCOMPLETE_BIRTH_PAGINATION_TEST";
export const CLEAR_BIRTH_OPTIONS = "CLEAR_BIRTH_OPTIONS";

export const AUTOCOMPLETE_DEATH_TEST = "AUTOCOMPLETE_DEATH_TEST";
export const AUTOCOMPLETE_DEATH_PAGINATION_TEST = "AUTOCOMPLETE_DEATH_PAGINATION_TEST";
export const CLEAR_DEATH_OPTIONS = "CLEAR_DEATH_OPTIONS";
export const CLEAR_RESIDENCE_OPTIONS = "CLEAR_RESIDENCE_OPTIONS";

export const SAVE_PROFILE_IMAGE = "SAVE_PROFILE_IMAGE";
export const IMAGE_LOADING = "IMAGE_LOADING";
export const CLEAR_IMAGE = "CLEAR_IMAGE";
export const REFETCH_PERSON_INFO = "REFETCH_PERSON_INFO";
export const REFETCH_PERSON_BASIC_INFO = "REFETCH_PERSON_BASIC_INFO";
export const PROFILE_IMAGE_LOADING = "PROFILE_IMAGE_LOADING";
export const GET_PROFILE_IMAGE = "GET_PROFILE_IMAGE";
export const FETCHING_IMAGE = "FETCHING_IMAGE";
export const SHOW_IMAGE = "SHOW_IMAGE";

export const SET_MODAL_DATA = "SET_MODAL_DATA";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const SHOW_PROFILE = "SHOW_PROFILE";
export const SAVE_PROFILE = "SAVE_PROFILE";
export const GET_USER_PROFILE_IMAGE = "GET_USER_PROFILE_IMAGE";
export const CLEAR_PROFILE_IMAGE = "CLEAR_PROFILE_IMAGE";
export const PROFILE_CHANGED = "PROFILE_CHANGED";
export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";
export const CLEAR_EDIT_IMAGE = "CLEAR_EDIT_IMAGE";
export const SHOW_EDIT_IMAGE = "SHOW_EDIT_IMAGE";
export const PROFILE_IMAGE_UPLOADED = "PROFILE_IMAGE_UPLOADED";
export const REFETCH_PAGE_AFTER_UPLOAD = "REFETCH_PAGE_AFTER_UPLOAD";
export const CLEAR_REFETCH_INFO = "CLEAR_REFETCH_INFO";
export const ADD_PERSON_THUMBNAIL = "ADD_PERSON_THUMBNAIL";
export const IMAGE_UNLOADING = "IMAGE_UNLOADING";
export const ADD_PROFILE_THUMBNAIL = " ADD_PROFILE_THUMBNAIL";
export const ACCESS_CODE_VALIDATING = "ACCESS_CODE_VALIDATING";
export const ACCESS_CODE_INVALID = "ACCESS_CODE_INVALID";
export const ACCESS_CODE_VALID = "ACCESS_CODE_VALID";
export const CLEAR_ACCESS = "CLEAR_ACCESS";

export const ADD_PERSON_HERO_THUMBNAIL = "ADD_PERSON_HERO_THUMBNAIL";
export const SAVE_HERO_IMAGE = "SAVE_HERO_IMAGE";
export const THUMBNAIL_UPLOAD_COMPLETE = "THUMBNAIL_UPLOAD_COMPLETE";
export const GET_HERO_IMAGE = "GET_HERO_IMAGE";
export const RESET_MEDIA = "RESET_MEDIA";
export const CLEAR_STORY_LIKES_PERSONS = "CLEAR_STORY_LIKES_PERSONS";
export const FEATURED_STORY_ID_REDIRECT = "FEATURED_STORY_ID_REDIRECT";

export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_ERROR = "UPDATE_EMAIL_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_ORIGINAL_EMAIL = "UPDATE_ORIGINAL_EMAIL";
export const PROFILE_IMAGE_DELETED = "PROFILE_IMAGE_DELETED";
export const APP_ERROR_STATE = "APP_ERROR_STATE";
export const CLEAR_APP_ERROR_STATE = "CLEAR_APP_ERROR_STATE";

export const DIRECT_CHILDREN = "DIRECT_CHILDREN";
export const CLEAR_EVENT_INFO = "CLEAR_EVENT_INFO";

export const DELETING_EVENT = "DELETING_EVENT";
export const DELETED_EVENT = "DELETED_EVENT";
export const SAVING_MODAL = "SAVING_MODAL";
export const SAVED_MODAL = "SAVED_MODAL";
export const MILO_MODAL = "MILO_MODAL";
export const DELETE_PERSON_LOADING = "DELETE_PERSON_LOADING";
export const DELETE_PERSON_LOADED = "DELETE_PERSON_LOADED";
export const SET_MILO_DETAILS = "SET_MILO_DETAILS";
export const REFETCH_FAMILY_INFO = "REFETCH_FAMILY_INFO";
export const REFETCH_FAMILY_INFO_COMPLETE = "REFETCH_FAMILY_INFO_COMPLETE";

export const FOOTER_STYLE_GRAY = "FOOTER_STYLE_GRAY";
export const FOOTER_STYLE_WHITE = "FOOTER_STYLE_WHITE";
export const SHOW_LEFTSIDEBAR = "SHOW_LEFTSIDEBAR";
export const SHOW_HEADER = "SHOW_HEADER";
export const SHOW_FOOTER = "SHOW_FOOTER";
export const CURRENTTOPICCLEAR = "CURRENTTOPICCLEAR";

export const SETMEMBERFOLLOWUNFOLLOW = "SETMEMBERFOLLOWUNFOLLOW";
export const CLEARCOMMENTS = "CLEARCOMMENTS";
export const HOMESCROLLCORD = "HOMESCROLLCORD";
export const HOMESCROLLCORDFEED = "HOMESCROLLCORDFEED";
export const CLEARHOMEPAGESTORIES = "CLEARHOMEPAGESTORIES";
export const CLEARGROUPPAGESTORIES = "CLEARGROUPPAGESTORIES";
export const INITIALUSERROLE = "INITIALUSERROLE";
export const EMPTYGROUPDETAILS = "EMPTYGROUPDETAILS";
export const UPDATE_COOKIE_GROUP_INFO = "UPDATE_COOKIE_GROUP_INFO";
export const RESETINVITEDETAILS = "RESETINVITEDETAILS";

export const PENDING_NOTIFICATION_SNACKBAR = "PENDING_NOTIFICATION_SNACKBAR";
export const CLEAR_GEDCOM = "CLEAR_GEDCOM";
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const LEAVE_GROUP = "LEAVE_GROUPS";
export const UPDATE_HEADER_TITLE = "UPDATE_HEADER_TITLE";
export const EMPTYGROUPMEMBERS = "EMPTYGROUPMEMBERS";
export const UPDATE_PROMPTS_TELL_STORY = "UPDATE_PROMPTS_TELL_STORY";
export const UPDATE_PROMPTS_GROUP = "UPDATE_PROMPTS_GROUP";
export const UPDATE_PROMPTS_SEARCH = "UPDATE_PROMPTS_SEARCH";
export const UPDATE_PROMPTS_FEATURE_STORY = "UPDATE_PROMPTS_FEATURE_STORY";
export const GROUP_PROMPT_HOMEPAGE = "GROUP_PROMPT_HOMEPAGE";
export const UPDATE_PROMPTS_FREE_TRIAL = "UPDATE_PROMPTS_FREE_TRIAL";
export const STORY_STARTERS_TEXT = "STORY_STARTERS_TEXT";
export const UPDATE_INFO_MODAL_TOOLTIP = "UPDATE_INFO_MODAL_TOOLTIP";
export const UPDATE_FIRST_HINT_SEEN = "UPDATE_FIRST_HINT_SEEN";
export const SET_START_TREE_REQUEST_STATUS = "SET_START_TREE_REQUEST_STATUS";
export const REVERT_START_TREE_REQUEST_STATUS = "REVERT_START_TREE_REQUEST_STATUS";
export const SURNAME_DISTRIBUTION = "SURNAME_DISTRIBUTION";
export const SURNAME_MEANING = "SURNAME_MEANING";

export const DELETE_PET_LOADING = "DELETE_PET_LOADING";
export const DELETE_PET_LOADED = "DELETE_PET_LOADED";
export const DELETE_PET_BG_IMAGE = "DELETE_PET_BG_IMAGE";
export const DELETE_PET_PROFILE_IMAGE = "DELETE_PET_PROFILE_IMAGE";
export const ADD_PET_THUMBNAIL = "ADD_PET_THUMBNAIL";
export const ADD_PET_HERO_THUMBNAIL = "ADD_PET_HERO_THUMBNAIL";
export const DELETE_HINTS = "DELETE_HINTS";
export const REDIRECT_TO_REGISTER = "REDIRECT_TO_REGISTER";
export const TREE_PROGRESS_PAYLOAD = "TREE_PROGRESS_PAYLOAD";
export const SET_IMPORTING_INPROGRESS = "SET_IMPORTING_INPROGRESS";

export const SAVEDRAFTPREVIEWSTORE = "SAVEDRAFTPREVIEWSTORE";
export const SAVEDRAFTPREVIEWREMOVE = "SAVEDRAFTPREVIEWREMOVE";
export const REFETCH_TREE_PROGRESS = "REFETCH_TREE_PROGRESS";

export const OWN_STORY_LIST_UPDATE_REDUX = "OWN_STORY_LIST_UPDATE_REDUX";
export const GROUP_STORY_LIST_UPDATE_REDUX = "GROUP_STORY_LIST_UPDATE_REDUX";

export const UPDATE_LAST_VISIT_GROUP = "UPDATE_LAST_VISIT_GROUP";
export const UPDATE_ORDER_USER_GROUP = "UPDATE_ORDER_USER_GROUP";

export const DELETEINDIVDUALOWNSTORY = "DELETEINDIVDUALOWNSTORY";

export const REMOVE_MEDIA_FROM_STORIES = "REMOVE_MEDIA_FROM_STORIES";

export const NEW_NODE_HIGHTLIGHT = "NEW_NODE_HIGHTLIGHT";
export const COLLECTIONID_HIGHEST_HINT = "COLLECTIONID_HIGHEST_HINT";

// print book
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const REORDER_STORY_BOOK_ITEM = "REORDER_STORY_BOOK_ITEM";

export const UPDATE_DRAFT_STORY_COUNT = "UPDATE_DRAFT_STORY_COUNT";
export const UPDATE_SELECTED_STORY_COUNT = "UPDATE_SELECTED_STORY_COUNT";

export const AUTH_WINDOW_CLOSED = "AUTH_WINDOW_CLOSED";

export const GET_FS_USER = "GET_FS_USER";
export const GET_IMPORTED_TREE = "GET_IMPORTED_TREE";
export const UPDATE_USER_BITHDATE = "UPDATE_USER_BITHDATE";
export const FAMILY_SEARCH_IMPORT_PROGRESS_COMPLETE = "FAMILY_SEARCH_IMPORT_PROGRESS_COMPLETE";
export const FAMILY_SEARCH_MEMORIES_IMPORT_COMPLETE = "FAMILY_SEARCH_MEMORIES_IMPORT_COMPLETE";
export const CLEAR_MEMORIES_SNACKBAR = "CLEAR_MEMORIES_SNACKBAR";
export const SHOW_HIDE_VIDEO_FOR_LDS_USER = "SHOW_HIDE_VIDEO_FOR_LDS_USER";
export const UPDATE_PROMPT_RECIPE = "UPDATE_PROMPT_RECIPE";
export const UPDATE_TOOLTIP_RECIPE = "UPDATE_TOOLTIP_RECIPE";
export const START_MEMORIES_IMPORT = "START_MEMORIES_IMPORT";

export const ERROR_SPOUSES = "ERROR_SPOUSES";
export const ERROR_PARENTS = "ERROR_PARENTS";
export const ERROR_ALLLIFEEVENTS = "ERROR_ALLLIFEEVENTS";
export const RETRY_FAILED_STORY = "RETRY_FAILED_STORY";
export const RESET_OWN_STORY_OFFSET = "RESET_OWN_STORY_OFFSET";
export const UPDATE_USER_CACHE = "UPDATE_USER_CACHE";
export const UPDATE_USER_FOLLOW_CACHE = "UPDATE_USER_FOLLOW_CACHE";
export const REMOVE_UNFOLOW_AUTHOR_STORY="REMOVE_UNFOLOW_AUTHOR_STORY";

export const GETRIGHTPANELDETAILS_SUCCESS = "GETRIGHTPANELDETAILS_SUCCESS";

export const ONBOARDING_MODULE = "ONBOARDING_MODULE";
export const ONBOARDING_CHECKLIST = "ONBOARDING_CHECKLIST";

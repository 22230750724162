/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Project2Data,
  Project2Payload,
  Project3Data,
  Project3Params,
  Project4Data,
  ProjectAddStoriesData,
  ProjectAddStoriesPayload,
  ProjectCloneData,
  ProjectData,
  ProjectHidePromptData,
  ProjectHidePromptPayload,
  ProjectPayload,
  ProjectRemoveStoriesData,
  ProjectRemoveStoriesPayload,
  ProjectShowPromptData,
  ProjectShowPromptPayload,
  ProjectStoriesSetOrderData,
  ProjectStoriesSetOrderPayload,
  ProjectTemplatesData,
  ProjectTemplatesReloadData,
  V2ProjectRecentlyViewedData
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Project extends HttpClient {
  /**
   * No description
   *
   * @tags Project
   * @name Project
   * @request POST:/api/Project
   * @secure
   */
  project = (data: ProjectPayload, params: RequestParams = {}) =>
    this.request<ProjectData, any>({
      path: `/api/Project`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name Project2
   * @request PUT:/api/Project
   * @originalName project
   * @duplicate
   * @secure
   */
  project2 = (data: Project2Payload, params: RequestParams = {}) =>
    this.request<Project2Data, any>({
      path: `/api/Project`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name Project3
   * @request GET:/api/Project
   * @originalName project
   * @duplicate
   * @secure
   */
  project3 = (query: Project3Params, params: RequestParams = {}) =>
    this.request<Project3Data, any>({
      path: `/api/Project`,
      method: "GET",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectClone
   * @request POST:/api/Project/{projectId}/clone
   * @secure
   */
  projectClone = (projectId: string, params: RequestParams = {}) =>
    this.request<ProjectCloneData, any>({
      path: `/api/Project/${projectId}/clone`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name Project4
   * @request DELETE:/api/Project/{projectId}
   * @originalName project
   * @duplicate
   * @secure
   */
  project4 = (projectId: string, params: RequestParams = {}) =>
    this.request<Project4Data, any>({
      path: `/api/Project/${projectId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectStoriesSetOrder
   * @request POST:/api/Project/{projectId}/stories/set-order
   * @secure
   */
  projectStoriesSetOrder = (
    projectId: string,
    data: ProjectStoriesSetOrderPayload,
    params: RequestParams = {}
  ) =>
    this.request<ProjectStoriesSetOrderData, any>({
      path: `/api/Project/${projectId}/stories/set-order`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectAddStories
   * @request POST:/api/Project/add-stories
   * @secure
   */
  projectAddStories = (data: ProjectAddStoriesPayload, params: RequestParams = {}) =>
    this.request<ProjectAddStoriesData, any>({
      path: `/api/Project/add-stories`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectRemoveStories
   * @request POST:/api/Project/remove-stories
   * @secure
   */
  projectRemoveStories = (data: ProjectRemoveStoriesPayload, params: RequestParams = {}) =>
    this.request<ProjectRemoveStoriesData, any>({
      path: `/api/Project/remove-stories`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectHidePrompt
   * @request POST:/api/Project/hide-prompt
   * @secure
   */
  projectHidePrompt = (data: ProjectHidePromptPayload, params: RequestParams = {}) =>
    this.request<ProjectHidePromptData, any>({
      path: `/api/Project/hide-prompt`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectShowPrompt
   * @request POST:/api/Project/show-prompt
   * @secure
   */
  projectShowPrompt = (data: ProjectShowPromptPayload, params: RequestParams = {}) =>
    this.request<ProjectShowPromptData, any>({
      path: `/api/Project/show-prompt`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectTemplates
   * @request GET:/api/Project/templates
   * @secure
   */
  projectTemplates = (params: RequestParams = {}) =>
    this.request<ProjectTemplatesData, any>({
      path: `/api/Project/templates`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name ProjectTemplatesReload
   * @request GET:/api/Project/templates/reload
   * @secure
   */
  projectTemplatesReload = (params: RequestParams = {}) =>
    this.request<ProjectTemplatesReloadData, any>({
      path: `/api/Project/templates/reload`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags Project
   * @name V2ProjectRecentlyViewed
   * @request GET:/api/v2/Project/recently-viewed
   * @secure
   */
  v2ProjectRecentlyViewed = (params: RequestParams = {}) =>
    this.request<V2ProjectRecentlyViewedData, any>({
      path: `/api/v2/Project/recently-viewed`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AdminUserAddbookpublishplanforannualsubscribersData,
  AdminUserAddbookpublishplanforannualsubscribersParams,
  AdminUserAssignadminroleData,
  AdminUserIndexUserMediaData,
  AdminUserIndexUserMediaParams,
  AdminUserRefreshOfflineAccessTokensData,
  UserAddRecentlyViewedGroupData,
  UserAddRecentlyViewedProjectData,
  UserAddrecentlyviewedpeopleData,
  UserAddrecentlyviewedtreeData,
  UserAssignbookpublishingplanData,
  UserAssignbookpublishingplanParams,
  UserCancelsubscriptionData,
  UserCancelsubscriptionPayload,
  UserCcpaData,
  UserCcpaPayload,
  UserChangesubscriptionData,
  UserChangesubscriptionPayload,
  UserCreateDefaultTreeData,
  UserCreateSubscriptionUserData,
  UserCreateSubscriptionUserPayload,
  UserCreatestorieduserData,
  UserDeleteaccountData,
  UserDeleteuserprofileimageData,
  UserDismissFreeTrialPromptData,
  UserDismissGroupPromptData,
  UserDismissRecipePromptData,
  UserDismissRecipeToolTipPromptData,
  UserDismissSearchPromptData,
  UserDismissStoryPromptData,
  UserDismissTopicPromptData,
  UserDismissquickviewmodaltooltipData,
  UserFirstHintSeenData,
  UserFollowData,
  UserFollowtopicData,
  UserFreeldsaccountData,
  UserFreeldssubscriptionData,
  UserInitializeOfflineFamilySearchAccessData,
  UserInitializeOfflineFamilySearchAccessParams,
  UserLinkaccountsbyemailData,
  UserMarknotificationreadData,
  UserMigrateuserData,
  UserMilopreferencesData,
  UserMilopreferencesPayload,
  UserMilopreferencesupdateData,
  UserMilopreferencesupdatePayload,
  UserNauserData,
  UserNauserPayload,
  UserReactivateSubscriptionData,
  UserReactivateSubscriptionParams,
  UserRemovePendingSubscriptionData,
  UserRemovePendingSubscriptionParams,
  UserSessiongroupmemberinfoData,
  UserSessiongroupmemberinfoPayload,
  UserSubscribeData,
  UserSubscribePayload,
  UserSubscriptioninfoData,
  UserSubscriptioninfoPayload,
  UserUndodeleteaccountrequestData,
  UserUnfollowData,
  UserUnfollowtopicData,
  UserUpdateAuth0IdData,
  UserUpdatePromptOrderForOldUsersData,
  UserUpdateStatusForOldUserFollowingFeatureTopicData,
  UserUpdatelasttimeusersawnotificationData,
  UserUpdatepaymentcardData,
  UserUpdatepaymentcardPayload,
  UserUpdateuserData,
  UserUpdateuserPayload,
  UserUpdateuseremailData,
  UserUpdateuseremailPayload,
  UserUserRegistrationQuestionnaireAnswersData,
  UserUserRegistrationQuestionnaireAnswersPayload,
  UserUserRegistrationQuestionnaireSeenData,
  UserVerifyaccessData,
  UserVerifyaccessPayload
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class User extends HttpClient {
  /**
   * No description
   *
   * @tags User
   * @name UserDeleteuserprofileimage
   * @request DELETE:/api/User/deleteuserprofileimage/{userId}
   * @secure
   */
  userDeleteuserprofileimage = (userId: string, params: RequestParams = {}) =>
    this.request<UserDeleteuserprofileimageData, any>({
      path: `/api/User/deleteuserprofileimage/${userId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserDeleteaccount
   * @request DELETE:/api/User/deleteaccount/{userId}
   * @secure
   */
  userDeleteaccount = (userId: string, params: RequestParams = {}) =>
    this.request<UserDeleteaccountData, any>({
      path: `/api/User/deleteaccount/${userId}`,
      method: "DELETE",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUndodeleteaccountrequest
   * @request PUT:/api/User/undodeleteaccountrequest/{userId}
   * @secure
   */
  userUndodeleteaccountrequest = (userId: string, params: RequestParams = {}) =>
    this.request<UserUndodeleteaccountrequestData, any>({
      path: `/api/User/undodeleteaccountrequest/${userId}`,
      method: "PUT",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserNauser
   * @request POST:/api/User/nauser
   * @secure
   */
  userNauser = (data: UserNauserPayload, params: RequestParams = {}) =>
    this.request<UserNauserData, any>({
      path: `/api/User/nauser`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserAddrecentlyviewedpeople
   * @request POST:/api/User/addrecentlyviewedpeople/{treeId}/{treePersonId}
   * @secure
   */
  userAddrecentlyviewedpeople = (
    treeId: string,
    treePersonId: string,
    params: RequestParams = {}
  ) =>
    this.request<UserAddrecentlyviewedpeopleData, any>({
      path: `/api/User/addrecentlyviewedpeople/${treeId}/${treePersonId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserCreatestorieduser
   * @request POST:/api/User/createstorieduser/{accountSource}/{familySearchId}
   * @secure
   */
  userCreatestorieduser = (
    accountSource: string,
    familySearchId: string,
    params: RequestParams = {}
  ) =>
    this.request<UserCreatestorieduserData, any>({
      path: `/api/User/createstorieduser/${accountSource}/${familySearchId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserMigrateuser
   * @request POST:/api/User/migrateuser
   * @secure
   */
  userMigrateuser = (params: RequestParams = {}) =>
    this.request<UserMigrateuserData, any>({
      path: `/api/User/migrateuser`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUpdateuser
   * @request POST:/api/User/updateuser
   * @secure
   */
  userUpdateuser = (data: UserUpdateuserPayload, params: RequestParams = {}) =>
    this.request<UserUpdateuserData, any>({
      path: `/api/User/updateuser`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUpdateuseremail
   * @request POST:/api/User/updateuseremail
   * @secure
   */
  userUpdateuseremail = (data: UserUpdateuseremailPayload, params: RequestParams = {}) =>
    this.request<UserUpdateuseremailData, any>({
      path: `/api/User/updateuseremail`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserMilopreferences
   * @request POST:/api/User/milopreferences
   * @secure
   */
  userMilopreferences = (data: UserMilopreferencesPayload, params: RequestParams = {}) =>
    this.request<UserMilopreferencesData, any>({
      path: `/api/User/milopreferences`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserMilopreferencesupdate
   * @request POST:/api/User/milopreferencesupdate
   * @secure
   */
  userMilopreferencesupdate = (
    data: UserMilopreferencesupdatePayload,
    params: RequestParams = {}
  ) =>
    this.request<UserMilopreferencesupdateData, any>({
      path: `/api/User/milopreferencesupdate`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserVerifyaccess
   * @request POST:/api/User/verifyaccess
   * @secure
   */
  userVerifyaccess = (data: UserVerifyaccessPayload, params: RequestParams = {}) =>
    this.request<UserVerifyaccessData, any>({
      path: `/api/User/verifyaccess`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserCreateDefaultTree
   * @request POST:/api/User/create-default-tree/{treeId}/{homePersonId}
   * @secure
   */
  userCreateDefaultTree = (treeId: string, homePersonId: string, params: RequestParams = {}) =>
    this.request<UserCreateDefaultTreeData, any>({
      path: `/api/User/create-default-tree/${treeId}/${homePersonId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserAddrecentlyviewedtree
   * @request POST:/api/User/addrecentlyviewedtree/{treeId}
   * @secure
   */
  userAddrecentlyviewedtree = (treeId: string, params: RequestParams = {}) =>
    this.request<UserAddrecentlyviewedtreeData, any>({
      path: `/api/User/addrecentlyviewedtree/${treeId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserAddRecentlyViewedGroup
   * @request POST:/api/User/add-recently-viewed-group/{groupId}
   * @secure
   */
  userAddRecentlyViewedGroup = (groupId: string, params: RequestParams = {}) =>
    this.request<UserAddRecentlyViewedGroupData, any>({
      path: `/api/User/add-recently-viewed-group/${groupId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserAddRecentlyViewedProject
   * @request POST:/api/User/add-recently-viewed-project/{projectId}
   * @secure
   */
  userAddRecentlyViewedProject = (projectId: string, params: RequestParams = {}) =>
    this.request<UserAddRecentlyViewedProjectData, any>({
      path: `/api/User/add-recently-viewed-project/${projectId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserMarknotificationread
   * @request PUT:/api/User/marknotificationread/{notificationId}
   * @secure
   */
  userMarknotificationread = (notificationId: string, params: RequestParams = {}) =>
    this.request<UserMarknotificationreadData, any>({
      path: `/api/User/marknotificationread/${notificationId}`,
      method: "PUT",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUpdatelasttimeusersawnotification
   * @request PUT:/api/User/updatelasttimeusersawnotification
   * @secure
   */
  userUpdatelasttimeusersawnotification = (params: RequestParams = {}) =>
    this.request<UserUpdatelasttimeusersawnotificationData, any>({
      path: `/api/User/updatelasttimeusersawnotification`,
      method: "PUT",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserFollow
   * @request POST:/api/User/follow/{userId}
   * @secure
   */
  userFollow = (userId: string, params: RequestParams = {}) =>
    this.request<UserFollowData, any>({
      path: `/api/User/follow/${userId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUnfollow
   * @request POST:/api/User/unfollow/{userId}
   * @secure
   */
  userUnfollow = (userId: string, params: RequestParams = {}) =>
    this.request<UserUnfollowData, any>({
      path: `/api/User/unfollow/${userId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserFollowtopic
   * @request POST:/api/User/followtopic/{topicId}
   * @secure
   */
  userFollowtopic = (topicId: string, params: RequestParams = {}) =>
    this.request<UserFollowtopicData, any>({
      path: `/api/User/followtopic/${topicId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUnfollowtopic
   * @request POST:/api/User/unfollowtopic/{topicId}
   * @secure
   */
  userUnfollowtopic = (topicId: string, params: RequestParams = {}) =>
    this.request<UserUnfollowtopicData, any>({
      path: `/api/User/unfollowtopic/${topicId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserCcpa
   * @request PUT:/api/User/ccpa
   * @secure
   */
  userCcpa = (data: UserCcpaPayload, params: RequestParams = {}) =>
    this.request<UserCcpaData, any>({
      path: `/api/User/ccpa`,
      method: "PUT",
      body: data,
      secure: true,
      allowAnonymous: true,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserSubscriptioninfo
   * @request POST:/api/User/subscriptioninfo
   * @secure
   */
  userSubscriptioninfo = (data: UserSubscriptioninfoPayload, params: RequestParams = {}) =>
    this.request<UserSubscriptioninfoData, any>({
      path: `/api/User/subscriptioninfo`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserSessiongroupmemberinfo
   * @request POST:/api/User/sessiongroupmemberinfo
   * @secure
   */
  userSessiongroupmemberinfo = (
    data: UserSessiongroupmemberinfoPayload,
    params: RequestParams = {}
  ) =>
    this.request<UserSessiongroupmemberinfoData, any>({
      path: `/api/User/sessiongroupmemberinfo`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserDismissTopicPrompt
   * @request POST:/api/User/dismissTopicPrompt
   * @secure
   */
  userDismissTopicPrompt = (params: RequestParams = {}) =>
    this.request<UserDismissTopicPromptData, any>({
      path: `/api/User/dismissTopicPrompt`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserDismissStoryPrompt
   * @request POST:/api/User/dismissStoryPrompt
   * @secure
   */
  userDismissStoryPrompt = (params: RequestParams = {}) =>
    this.request<UserDismissStoryPromptData, any>({
      path: `/api/User/dismissStoryPrompt`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserDismissRecipePrompt
   * @request POST:/api/User/dismissRecipePrompt
   * @secure
   */
  userDismissRecipePrompt = (params: RequestParams = {}) =>
    this.request<UserDismissRecipePromptData, any>({
      path: `/api/User/dismissRecipePrompt`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserDismissRecipeToolTipPrompt
   * @request POST:/api/User/dismissRecipeToolTipPrompt
   * @secure
   */
  userDismissRecipeToolTipPrompt = (params: RequestParams = {}) =>
    this.request<UserDismissRecipeToolTipPromptData, any>({
      path: `/api/User/dismissRecipeToolTipPrompt`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserDismissGroupPrompt
   * @request POST:/api/User/dismissGroupPrompt
   * @secure
   */
  userDismissGroupPrompt = (params: RequestParams = {}) =>
    this.request<UserDismissGroupPromptData, any>({
      path: `/api/User/dismissGroupPrompt`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserDismissSearchPrompt
   * @request POST:/api/User/dismissSearchPrompt
   * @secure
   */
  userDismissSearchPrompt = (params: RequestParams = {}) =>
    this.request<UserDismissSearchPromptData, any>({
      path: `/api/User/dismissSearchPrompt`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserDismissFreeTrialPrompt
   * @request POST:/api/User/dismissFreeTrialPrompt
   * @secure
   */
  userDismissFreeTrialPrompt = (params: RequestParams = {}) =>
    this.request<UserDismissFreeTrialPromptData, any>({
      path: `/api/User/dismissFreeTrialPrompt`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUpdateStatusForOldUserFollowingFeatureTopic
   * @request POST:/api/User/updateStatusForOldUserFollowingFeatureTopic
   * @secure
   */
  userUpdateStatusForOldUserFollowingFeatureTopic = (params: RequestParams = {}) =>
    this.request<UserUpdateStatusForOldUserFollowingFeatureTopicData, any>({
      path: `/api/User/updateStatusForOldUserFollowingFeatureTopic`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUpdatePromptOrderForOldUsers
   * @request POST:/api/User/updatePromptOrderForOldUsers
   * @secure
   */
  userUpdatePromptOrderForOldUsers = (params: RequestParams = {}) =>
    this.request<UserUpdatePromptOrderForOldUsersData, any>({
      path: `/api/User/updatePromptOrderForOldUsers`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserDismissquickviewmodaltooltip
   * @request POST:/api/User/dismissquickviewmodaltooltip
   * @secure
   */
  userDismissquickviewmodaltooltip = (params: RequestParams = {}) =>
    this.request<UserDismissquickviewmodaltooltipData, any>({
      path: `/api/User/dismissquickviewmodaltooltip`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserCreateSubscriptionUser
   * @request POST:/api/User/create-subscription-user
   * @secure
   */
  userCreateSubscriptionUser = (
    data: UserCreateSubscriptionUserPayload,
    params: RequestParams = {}
  ) =>
    this.request<UserCreateSubscriptionUserData, any>({
      path: `/api/User/create-subscription-user`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserSubscribe
   * @request POST:/api/User/subscribe
   * @secure
   */
  userSubscribe = (data: UserSubscribePayload, params: RequestParams = {}) =>
    this.request<UserSubscribeData, any>({
      path: `/api/User/subscribe`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserCancelsubscription
   * @request POST:/api/User/cancelsubscription
   * @secure
   */
  userCancelsubscription = (data: UserCancelsubscriptionPayload, params: RequestParams = {}) =>
    this.request<UserCancelsubscriptionData, any>({
      path: `/api/User/cancelsubscription`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserRemovePendingSubscription
   * @request POST:/api/User/remove-pending-subscription
   * @secure
   */
  userRemovePendingSubscription = (
    query: UserRemovePendingSubscriptionParams,
    params: RequestParams = {}
  ) =>
    this.request<UserRemovePendingSubscriptionData, any>({
      path: `/api/User/remove-pending-subscription`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserReactivateSubscription
   * @request POST:/api/User/reactivate-subscription
   * @secure
   */
  userReactivateSubscription = (
    query: UserReactivateSubscriptionParams,
    params: RequestParams = {}
  ) =>
    this.request<UserReactivateSubscriptionData, any>({
      path: `/api/User/reactivate-subscription`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserChangesubscription
   * @request POST:/api/User/changesubscription
   * @secure
   */
  userChangesubscription = (data: UserChangesubscriptionPayload, params: RequestParams = {}) =>
    this.request<UserChangesubscriptionData, any>({
      path: `/api/User/changesubscription`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUpdatepaymentcard
   * @request POST:/api/User/updatepaymentcard
   * @secure
   */
  userUpdatepaymentcard = (data: UserUpdatepaymentcardPayload, params: RequestParams = {}) =>
    this.request<UserUpdatepaymentcardData, any>({
      path: `/api/User/updatepaymentcard`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUserRegistrationQuestionnaireAnswers
   * @request POST:/api/User/userRegistrationQuestionnaireAnswers
   * @secure
   */
  userUserRegistrationQuestionnaireAnswers = (
    data: UserUserRegistrationQuestionnaireAnswersPayload,
    params: RequestParams = {}
  ) =>
    this.request<UserUserRegistrationQuestionnaireAnswersData, any>({
      path: `/api/User/userRegistrationQuestionnaireAnswers`,
      method: "POST",
      body: data,
      secure: true,
      allowAnonymous: false,
      type: ContentType.Json,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserFreeldsaccount
   * @request POST:/api/User/freeldsaccount
   * @secure
   */
  userFreeldsaccount = (params: RequestParams = {}) =>
    this.request<UserFreeldsaccountData, any>({
      path: `/api/User/freeldsaccount`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserFreeldssubscription
   * @request POST:/api/User/freeldssubscription
   * @secure
   */
  userFreeldssubscription = (params: RequestParams = {}) =>
    this.request<UserFreeldssubscriptionData, any>({
      path: `/api/User/freeldssubscription`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserInitializeOfflineFamilySearchAccess
   * @request POST:/api/User/initializeOfflineFamilySearchAccess
   * @secure
   */
  userInitializeOfflineFamilySearchAccess = (
    query: UserInitializeOfflineFamilySearchAccessParams,
    params: RequestParams = {}
  ) =>
    this.request<UserInitializeOfflineFamilySearchAccessData, any>({
      path: `/api/User/initializeOfflineFamilySearchAccess`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUserRegistrationQuestionnaireSeen
   * @request POST:/api/User/userRegistrationQuestionnaireSeen
   * @secure
   */
  userUserRegistrationQuestionnaireSeen = (params: RequestParams = {}) =>
    this.request<UserUserRegistrationQuestionnaireSeenData, any>({
      path: `/api/User/userRegistrationQuestionnaireSeen`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserLinkaccountsbyemail
   * @request POST:/api/User/{email}/linkaccountsbyemail
   * @secure
   */
  userLinkaccountsbyemail = (email: string, params: RequestParams = {}) =>
    this.request<UserLinkaccountsbyemailData, any>({
      path: `/api/User/${email}/linkaccountsbyemail`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserFirstHintSeen
   * @request POST:/api/User/firstHintSeen
   * @secure
   */
  userFirstHintSeen = (params: RequestParams = {}) =>
    this.request<UserFirstHintSeenData, any>({
      path: `/api/User/firstHintSeen`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserUpdateAuth0Id
   * @request POST:/api/User/{userId}/{auth0UserId}/UpdateAuth0Id
   * @secure
   */
  userUpdateAuth0Id = (userId: string, auth0UserId: string, params: RequestParams = {}) =>
    this.request<UserUpdateAuth0IdData, any>({
      path: `/api/User/${userId}/${auth0UserId}/UpdateAuth0Id`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name UserAssignbookpublishingplan
   * @request POST:/api/User/assignbookpublishingplan
   * @secure
   */
  userAssignbookpublishingplan = (
    query: UserAssignbookpublishingplanParams,
    params: RequestParams = {}
  ) =>
    this.request<UserAssignbookpublishingplanData, any>({
      path: `/api/User/assignbookpublishingplan`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name AdminUserAssignadminrole
   * @request POST:/api/admin/User/assignadminrole/{userId}
   * @secure
   */
  adminUserAssignadminrole = (userId: string, params: RequestParams = {}) =>
    this.request<AdminUserAssignadminroleData, any>({
      path: `/api/admin/User/assignadminrole/${userId}`,
      method: "POST",
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name AdminUserIndexUserMedia
   * @request POST:/api/admin/User/index-user-media
   * @secure
   */
  adminUserIndexUserMedia = (query: AdminUserIndexUserMediaParams, params: RequestParams = {}) =>
    this.request<AdminUserIndexUserMediaData, any>({
      path: `/api/admin/User/index-user-media`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name AdminUserAddbookpublishplanforannualsubscribers
   * @request POST:/api/admin/User/addbookpublishplanforannualsubscribers
   * @secure
   */
  adminUserAddbookpublishplanforannualsubscribers = (
    query: AdminUserAddbookpublishplanforannualsubscribersParams,
    params: RequestParams = {}
  ) =>
    this.request<AdminUserAddbookpublishplanforannualsubscribersData, any>({
      path: `/api/admin/User/addbookpublishplanforannualsubscribers`,
      method: "POST",
      query: query,
      secure: true,
      allowAnonymous: false,
      ...params
    }); /**
   * No description
   *
   * @tags User
   * @name AdminUserRefreshOfflineAccessTokens
   * @request GET:/api/admin/User/refresh_offline_access_tokens
   * @secure
   */
  adminUserRefreshOfflineAccessTokens = (params: RequestParams = {}) =>
    this.request<AdminUserRefreshOfflineAccessTokensData, any>({
      path: `/api/admin/User/refresh_offline_access_tokens`,
      method: "GET",
      secure: true,
      allowAnonymous: false,
      ...params
    });
}

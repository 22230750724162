import API from "API/__generated__";
import { UseDataLayer } from "hooks/usedataLayer";
import _ from "lodash";
import { AnyAction, Dispatch } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { getNameForPurchase } from "redux/helpers/dataLayerHelper";
import { RootState } from "redux/store";
import { sendRefersionCheckoutEvent } from "redux/utils/refreshSub";
import {
  SetCookieSubs,
  decodeJWTtoken,
  getGoogleSessionId,
  getOwner,
  paymentSuccessNewSubs,
  setStoryBookStudioPlans
} from "services";
import { futureDateByDay } from "utils";
import { CREDIT_CARD, PURCHASE, PURCHASE_ATTEMPT, PURCHASE_FAILURE } from "utils/constant";
import { REACT_APP_CLIENT_ID } from "utils/env";
import {
  subscriptionCancelTriggerEmail,
  subscriptionConfirmationTriggerEmail,
  subscriptionUpdateTriggerEmail
} from "utils/salesForceTriggerEmail";
import { v4 as uuidv4 } from "uuid";
import { strFirstUpCase } from "../../utils";
import * as CONSTANTS from "../constants/actionTypes";
import * as API_URLS from "../constants/apiUrl";
import { actionCreator, callApi } from "../utils";
import { claimSponsorshipApi } from "./group";
import { dismissFreeTrialPrompt } from "./homepagePrompts";
import { dataLayerPayment } from "./payments/utils/dataLayerPayment";
import { getDuration } from "./payments/utils/getDuration";
import { addMessage } from "./toastr";
import { dataLayerPurchaseFlowEvents } from "./utils/datalayer";

interface HeaderType {
  "wa-clientId": string;
  "wa-requestId": string;
  "wa-sessionId": string;
}

interface SubscriptionData {
  subscriptionId: string;
  planId: string;
  startDate: Date;
  endDate?: Date;
}

interface FormData {
  UserInformation?: {
    PlanId: string;
    EmailAddress: string;
  };
  AffiliateCartId?: string;
  shouldDisplayFreeTrialPrompt?: boolean;
  CouponDiscount?: number;
  CouponCode?: string | null;
}

interface PlanType {
  planid: string;
  planName: string;
  amount: number;
  prefix: string;
}

interface TaxDetailsFormData {
  amount: number;
  zipcode: string;
  country: string;
}

interface CouponCodeTaxDetailsFormData extends TaxDetailsFormData {
  taxableAmount: number;
}

interface UpgradePlanInformation {
  NewPlanId: string;
  NewPlanAmount: string;
  OldPrice: string;
}

interface PaymentOptionPayload {
  BillingInformation: {
    zipcode: string;
    country: string;
  };
  CreditCardInformation: {
    creditCardNumber: string;
    cvv2Number: string;
    expirationMonth: string;
    expirationYear: string;
  };
  UserInformation: {
    email: string;
    firstName: string;
    lastName: string;
    planId: string;
    id: string;
  };
  shouldDisplayFreeTrialPrompt: boolean;
  CouponCode: string;
  PlanId: string;
  AffiliateCartId: string;
  postalCode: string;
  country: string;
  plans: PlanType[];
  totalAmount: number;
  planFailedCallback: () => void;
  setordersummarypage: (value: boolean) => void;
  CouponDiscount: number;
  planInformation: {
    plan: PlanType[];
    tax: number;
    totalAmount: number;
  };
  isFF_NPAPAnalyticsEnabled: boolean;
  // isDowngrade?: boolean;
  refreshAuth0Token: () => void;
  refreshSubscription: (affiliateCartId: string) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  token: {
    email: string;
    id: string;
    payer_id: string;
    type: string;
  };
  type: string;
}

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, any, unknown, AnyAction>;
export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>;

const createHeaderForStoried = (): HeaderType => {
  return {
    "wa-clientId": REACT_APP_CLIENT_ID,
    "wa-requestId": uuidv4(),
    "wa-sessionId": getGoogleSessionId()
  };
};

const createHeader = (): { "X-Api-Key": string } => {
  return {
    "X-Api-Key": "C5BFF7F0-B4DF-475E-A331-F737424F013C"
  };
};

const subscriptionInfoFunc = (subData: SubscriptionData): void => {
  const endDate = futureDateByDay(7);
  SetCookieSubs(JSON.stringify({ ...subData, endDate }));
  submitSubscriptionInfo(subData).then((y) => y);
};

export const resetStatus = (): AppThunk => {
  return (dispatch: Dispatch) => {
    dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.RESET));
  };
};

export const hideBillingToast = (): AppThunk => {
  return (dispatch: Dispatch) => {
    dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.HIDEBILLINGTOAST));
  };
};

export const showBillingToast = (): AppThunk => {
  return (dispatch: Dispatch) => {
    dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.SHOWBILLINGTOAST));
  };
};

const dataLayerPaymentEvent = ({
  plan,
  isAnalyticsEnabled,
  recurlySubscriptionId = null,
  transactionId = "",
  paymentType,
  couponCode = null,
  tax,
  failureReason,
  eventType,
  couponDiscount,
  isDowngrade = false
}: {
  plan: PlanType[];
  isAnalyticsEnabled: boolean;
  recurlySubscriptionId?: string | null;
  transactionId?: string;
  tax: number;
  couponCode?: string | null;
  paymentType: string;
  failureReason?: string;
  eventType: string;
  couponDiscount?: number;
  isFreeTrial?: boolean;
  isDowngrade?: boolean;
}) => {
  if (isAnalyticsEnabled) {
    let payType = paymentType.toLowerCase();
    payType = payType === "apple" || payType === "google" ? payType + " pay" : payType;
    const ecommerceDetails = {
      paymentType: payType,
      orderCouponCode: couponCode,
      taxAmt: tax
    };

    const duration = getDuration(plan[0].prefix);
    const itemDetails = {
      id: plan[0].planid,
      name: plan[0].planName,
      price: isDowngrade ? 0 : plan[0].amount,
      discount: couponDiscount,
      duration: duration === "Yearly" ? "annual" : duration
    };
    dataLayerPurchaseFlowEvents({
      item: itemDetails,
      eventType,
      recurlySubscriptionId,
      transactionId,
      ...ecommerceDetails,
      ...(transactionId && { transactionId }),
      ...(failureReason && { failureReason })
    });
  }
};

export const submitCardDetails = (
  formData: FormData,
  setordersummarypage: (value: boolean) => void,
  userId: string,
  { plan, tax, totalAmount }: { plan: PlanType[]; tax: number; totalAmount: number },
  groupId: string,
  groupName: string,
  planFailedCallback: () => void,
  refreshAuth0Token: () => void,
  refreshSubscription: (affiliateCartId: string) => void,
  setSubmitting: (isSubmitting: boolean) => void,
  isAnalyticsEnabled: boolean
): AppThunk<void> => {
  const requestData = { ...formData };
  delete requestData.shouldDisplayFreeTrialPrompt;

  const couponCode = formData.CouponCode ?? "";
  const couponDiscount = formData.CouponDiscount ?? 0;

  dataLayerPaymentEvent({
    plan,
    isAnalyticsEnabled,
    tax,
    paymentType: CREDIT_CARD,
    eventType: PURCHASE_ATTEMPT,
    couponCode,
    couponDiscount
  });

  return async (dispatch, getState) => {
    try {
      const url = API_URLS.SUBMITCARDDETAILS;
      const staticHeader = createHeaderForStoried();
      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.REQUEST));
      setSubmitting(false);
      const res = await callApi("POST", url, requestData, false, staticHeader);
      paymentSuccessNewSubs(true);
      const subData: SubscriptionData = {
        subscriptionId: res?.data?.recurlySubscriptionUuid ?? "",
        planId: formData?.UserInformation?.PlanId ?? "",
        startDate: new Date()
      };
      subscriptionInfoFunc(subData);
      const planDetailsExists = formData?.UserInformation?.EmailAddress && plan?.[0];
      if (planDetailsExists) {
        subscriptionConfirmationTriggerEmail(subData, formData, plan, tax, totalAmount, userId);
      }
      if (formData.shouldDisplayFreeTrialPrompt) {
        dispatch(dismissFreeTrialPrompt() as any); // Explicitly cast to any if necessary
      }
      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.SUCCESS, requestData));

      setTimeout(async () => {
        await Promise.all([refreshAuth0Token()]);
        if (planDetailsExists) {
          if (isAnalyticsEnabled) {
            dataLayerPaymentEvent({
              plan,
              isAnalyticsEnabled,
              recurlySubscriptionId: res?.data?.recurlySubscriptionUuid ?? "",
              transactionId: res?.data?.recurlyTransactionUuid ?? "",
              tax,
              eventType: PURCHASE,
              paymentType: CREDIT_CARD,
              couponCode,
              couponDiscount
            });
          } else {
            const { basicName } = UseDataLayer();
            const userType = getNameForPurchase(basicName);
            const actionField = {
              id: res?.data?.recurlySubscriptionUuid,
              affiliation: "Storied",
              revenue: totalAmount ?? 0.0,
              tax: tax
            };
            const product = {
              id: plan[0].planid,
              name: plan[0].planName,
              brand: "Storied",
              quantity: 1,
              price: plan[0].amount,
              duration: getDuration(plan[0].prefix),
              userType: userType,
              user_id: getOwner()
            };
            const products = [product];
            dataLayerPayment(actionField, products);
          }
        }
      }, 10000);

      if (formData.AffiliateCartId) refreshSubscription(formData.AffiliateCartId);
    } catch (err: any) {
      console.log(err);
      setordersummarypage(false);
      let errorMsg: string = _.get(err, "response.data.errorMessage") || "Something went wrong";
      if (err?.response?.data?.chargeStatus === "TRANSACTION-CHARGEFAILED") {
        errorMsg =
          errorMsg + "<br>Please note that we do not accept prepaid cards as a form of payment.";
      }

      dataLayerPaymentEvent({
        plan,
        isAnalyticsEnabled,
        tax,
        paymentType: CREDIT_CARD,
        failureReason: errorMsg,
        eventType: PURCHASE_FAILURE,
        couponCode,
        couponDiscount
      });

      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.FAILURE, err?.response?.data));
      dispatch(addMessage(strFirstUpCase(errorMsg), "error"));
    }
  };
};

export const submitPaymentOptionPayment = (
  paymentOptionPayload: PaymentOptionPayload
): AppThunk<void> => {
  return async (dispatch) => {
    const {
      AffiliateCartId,
      BillingInformation,
      CouponCode,
      CreditCardInformation,
      PlanId,
      CouponDiscount,
      UserInformation,
      token,
      type,
      setordersummarypage,
      planInformation,
      refreshAuth0Token,
      refreshSubscription,
      setSubmitting,
      shouldDisplayFreeTrialPrompt,
      isFF_NPAPAnalyticsEnabled
      // isDowngrade
    } = paymentOptionPayload;
    const tokenUserInfo: any = decodeJWTtoken();
    const requestData = {
      affiliateCartId: AffiliateCartId,
      billingInformation: BillingInformation,
      couponCode: CouponCode,
      couponDiscount: CouponDiscount,
      creditCardInformation: CreditCardInformation,
      planId: PlanId,
      recurlyCustomFields: {},
      thirdPartyPaymentInformation: {
        isApplePay: type === "Apple",
        isGooglePay: type === "Google",
        isPayPal: type === "PayPal",
        paymentProcessingTokenId: token?.id
      },
      userInformation: {
        auth0Id: tokenUserInfo.sub,
        emailAddress: tokenUserInfo.email,
        firstname: tokenUserInfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"],
        lastname: tokenUserInfo["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"],
        planId: Number(PlanId),
        userId: tokenUserInfo.app_metadata_UserId
      }
    };

    const { plan, tax, totalAmount } = planInformation;

    try {
      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.REQUEST));
      setSubmitting?.(false);

      dataLayerPaymentEvent({
        plan,
        isAnalyticsEnabled: isFF_NPAPAnalyticsEnabled,
        recurlySubscriptionId: null,
        tax,
        couponCode: CouponCode,
        couponDiscount: CouponDiscount,
        paymentType: type,
        eventType: PURCHASE_ATTEMPT
      });

      const res = await API.User.userSubscribe(requestData);
      paymentSuccessNewSubs(true);

      const subData: SubscriptionData = {
        subscriptionId: res.recurlySubscriptionUuid ?? "",
        planId: UserInformation?.planId ?? "",
        startDate: new Date()
      };
      subscriptionInfoFunc(subData);

      const planInfoDetails = UserInformation?.email && plan?.[0];
      if (planInfoDetails) {
        subscriptionConfirmationTriggerEmail(
          subData,
          paymentOptionPayload,
          plan,
          tax,
          totalAmount,
          UserInformation.id
        );
      }

      dispatch(dismissFreeTrialPrompt());
      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.SUCCESS, requestData));

      setTimeout(() => {
        refreshAuth0Token();

        // if (!isDowngrade) {
        if (planInfoDetails) {
          dataLayerPaymentEvent({
            plan,
            isAnalyticsEnabled: isFF_NPAPAnalyticsEnabled,
            recurlySubscriptionId: res.recurlySubscriptionUuid,
            tax,
            eventType: PURCHASE,
            couponCode: CouponCode,
            couponDiscount: CouponDiscount,
            paymentType: type
          });
        }
        // }
      }, 10000);

      if (AffiliateCartId) sendRefersionCheckoutEvent(AffiliateCartId);
    } catch (err: any) {
      console.error(err);
      setordersummarypage?.(false);
      let errorMsg: string = _.get(err, "response.data.errorMessage") || "Something went wrong";
      if (err?.response?.data?.chargeStatus === "TRANSACTION-CHARGEFAILED") {
        errorMsg += "<br>Please note that we do not accept prepaid cards as a form of payment.";
      }
      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.FAILURE, err?.response?.data));
      dispatch(addMessage(strFirstUpCase(errorMsg), "error"));
      console.error(errorMsg);
      // if (!isDowngrade) {
      dataLayerPaymentEvent({
        plan,
        isAnalyticsEnabled: isFF_NPAPAnalyticsEnabled,
        recurlySubscriptionId: null,
        tax,
        couponCode: CouponCode,
        couponDiscount: CouponDiscount,
        paymentType: type,
        failureReason: errorMsg,
        eventType: PURCHASE_FAILURE
      });
      // }

      return Promise.reject(errorMsg);
    }
  };
};

const submitSubscriptionInfo = (data: SubscriptionData): Promise<any> => {
  const url = API_URLS.SubmitSubscriptionInfo,
    getState = () => {
      return {};
    },
    staticHeader = createHeaderForStoried();
  return callApi("POST", url, data, false, staticHeader)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTaxApiDetails = (
  taxDetailsformData: TaxDetailsFormData,
  setformData: (data: any) => void,
  formData: any,
  setordersummarypage: (value: boolean) => void
): AppThunk<Promise<number>> => {
  return async (dispatch: AppDispatch) => {
    const url = `${API_URLS.TAXAPIDETAILS}?amount=${taxDetailsformData.amount}&zipCode=${taxDetailsformData.zipcode}&country=${taxDetailsformData.country}`;
    const staticHeader = createHeaderForStoried();

    dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.REQUEST));
    dispatch(actionCreator(CONSTANTS.STORENEWTAXAPIDETAILS.REQUEST));
    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, res?.data));
      dispatch(actionCreator(CONSTANTS.STORENEWTAXAPIDETAILS.SUCCESS, res?.data ?? 0));
      setformData({ ...formData, ...taxDetailsformData });
      return Promise.resolve(res?.data as number); // Explicitly return a Promise<number>
    } catch (_err) {
      dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, 0));
      dispatch(actionCreator(CONSTANTS.STORENEWTAXAPIDETAILS.SUCCESS, 0));
      setformData({ ...formData, ...taxDetailsformData });
      return Promise.resolve(0); // Explicitly return a Promise<number>
    }
  };
};

export const getInitialTaxApiDetails = (
  taxDetailsformData: TaxDetailsFormData,
  setformData: (data: any) => void,
  formData: any
): AppThunk<void> => {
  return (dispatch) => {
    const url = `${API_URLS.TAXAPIDETAILS}?amount=${taxDetailsformData.amount}&zipCode=${taxDetailsformData.zipcode}&country=${taxDetailsformData.country}`;
    const staticHeader = createHeaderForStoried();

    dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.REQUEST));
    dispatch(actionCreator(CONSTANTS.STORENEWTAXAPIDETAILS.REQUEST));
    return callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, res?.data));
        dispatch(actionCreator(CONSTANTS.STORENEWTAXAPIDETAILS.SUCCESS, res?.data ?? 0));
        dispatch(storeOldTaxData(res?.data)); // Ensure this is correctly typed
        setformData({ ...formData, ...taxDetailsformData });
        return res?.data;
      })
      .catch((_err) => {
        dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, 0));
        dispatch(actionCreator(CONSTANTS.STORENEWTAXAPIDETAILS.SUCCESS, 0));
        setformData({ ...formData, ...taxDetailsformData });
      });
  };
};

export const getTaxForCouponCodeApiDetails = (
  taxDetailsformData: CouponCodeTaxDetailsFormData,
  setformData: (data: any) => void,
  formData: any
): AppThunk => {
  return (dispatch: Dispatch) => {
    const url = `${API_URLS.TAXAPIDETAILS}?amount=${taxDetailsformData.taxableAmount}&zipCode=${taxDetailsformData.zipcode}&country=${taxDetailsformData.country}`,
      staticHeader = createHeaderForStoried();
    dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.REQUEST));
    dispatch(actionCreator(CONSTANTS.STORENEWTAXAPIDETAILS.REQUEST));
    callApi("GET", url, {}, false, staticHeader)
      .then((res) => {
        dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, res?.data));
        dispatch(actionCreator(CONSTANTS.STORENEWTAXAPIDETAILS.SUCCESS, res?.data ?? 0));
        setformData({ ...formData, ...taxDetailsformData });
      })
      .catch((_err) => {
        dispatch(actionCreator(CONSTANTS.TAXAPIDETAILS.SUCCESS, 0));
        dispatch(actionCreator(CONSTANTS.STORENEWTAXAPIDETAILS.SUCCESS, 0));
        setformData({ ...formData, ...taxDetailsformData });
      });
  };
};

const storeOldTaxData = (paymentTax: number): AppThunk<Promise<void>> => {
  return async (dispatch: AppDispatch) => {
    dispatch(actionCreator(CONSTANTS.STOREOLDTAXAPIDETAILS.REQUEST, paymentTax));
    return Promise.resolve(); // Explicitly return a resolved Promise
  };
};

export const revertOldTaxData = (): AppThunk => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.REVERTOLDTAXAPIDETAILS.REQUEST));
  };
};

export const resetTaxDetails = (): AppThunk => {
  return (dispatch) => {
    dispatch(actionCreator(CONSTANTS.RESETTAXAPIDETAILS.REQUEST));
  };
};

export const updatePaymentTaxDetails = (
  taxDetailsformData: TaxDetailsFormData,
  setformData: (data: any) => void,
  formData: any,
  setordersummarypage: (value: boolean) => void
): AppThunk<Promise<number | void>> => {
  return async (dispatch: AppDispatch) => {
    try {
      const tax = await dispatch(
        getTaxApiDetails(taxDetailsformData, setformData, formData, setordersummarypage)
      );

      if (typeof tax === "number") {
        await dispatch(storeOldTaxData(tax));
      }

      return tax;
    } catch (error) {
      console.error("Error updating payment tax details:", error);
    }
  };
};

export const cancelPayment = (
  values: any,
  {
    setMemCancelModal,
    setMemDropModal,
    setSubmitting,
    refreshAuth0Token
  }: {
    setMemCancelModal: (value: boolean) => void;
    setMemDropModal: (value: boolean) => void;
    setSubmitting: (value: boolean) => void;
    refreshAuth0Token: () => void;
  }
): AppThunk<Promise<void>> => {
  const requestData = { ...values };
  delete requestData.PlanDetails;
  delete requestData.UserInformation;

  return async (dispatch: AppDispatch) => {
    const url = `${API_URLS.cancelSubscription()}`;
    const staticHeader = createHeaderForStoried();

    try {
      const res = await callApi("POST", url, requestData, false, staticHeader);

      if (res.data.success) {
        subscriptionCancelTriggerEmail(values);
        setSubmitting(false);
        setMemCancelModal(false);
        setMemDropModal(true);
        setTimeout(() => refreshAuth0Token(), 5000);
      } else {
        await dispatch(addMessage("Something Wrong Happened", "error"));
      }
    } catch (_err) {
      setSubmitting(false);
      await dispatch(addMessage("Something Wrong Happened", "error"));
    }
  };
};

export const getBillingInformation = (recurlyUuid: string): AppThunk<Promise<any | null>> => {
  return async (dispatch: AppDispatch) => {
    const url = `${API_URLS.GetBillingInformation(recurlyUuid)}`;
    const staticHeader = createHeaderForStoried();

    dispatch(actionCreator(CONSTANTS.BILLINGDETAILS.REQUEST));

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      if (res?.status === 200) {
        dispatch(actionCreator(CONSTANTS.BILLINGDETAILS.SUCCESS, res?.data));
        return res.data;
      } else {
        dispatch(actionCreator(CONSTANTS.BILLINGDETAILS.FAILURE, null));
        return null;
      }
    } catch (err) {
      console.error(err);
      dispatch(actionCreator(CONSTANTS.BILLINGDETAILS.FAILURE, null));
      await dispatch(addMessage("Card information is not found.", "error"));
      return null;
    }
  };
};

export const getCalculateRefundAmount = (
  planID: string,
  startDate: string
): AppThunk<Promise<any | null>> => {
  return async (dispatch: AppDispatch) => {
    const url = `${API_URLS.CalculateRefundAmount(planID, startDate)}`;
    const staticHeader = createHeaderForStoried();

    try {
      const res = await callApi("GET", url, {}, false, staticHeader);
      if (res?.status === 200) {
        return res.data;
      } else {
        await dispatch(addMessage("Card information is not found.", "error"));
        return null;
      }
    } catch (err) {
      console.error(err);
      await dispatch(addMessage("Card information is not found.", "error"));
      return null;
    }
  };
};

export const getCouponCodeDiscount = (data: {
  couponCode: string;
  planId?: string;
  emailAddress?: string;
}): AppThunk<Promise<any | null>> => {
  return async (dispatch: AppDispatch) => {
    try {
      const resp = await API.Users.usersCouponDetail(data);
      return resp;
    } catch (err) {
      return err;
    }
  };
};

const getrecurring = (
  messages: string[] | undefined
): { recurlySubId: string; recurlyTransId: string } => {
  let recurlySubId = "",
    recurlyTransId = "";
  if (messages && messages.length > 0) {
    recurlySubId =
      messages.find((message) => message.startsWith("NewRecurlySubscriptionId="))?.split("=")[1] ||
      "";
    recurlyTransId =
      messages.find((message) => message.startsWith("NewRecurlyTransactionId="))?.split("=")[1] ||
      "";
  }

  return { recurlySubId, recurlyTransId };
};

export const updateUpgradePlans = (
  formData: {
    UserInformation: any;
    UpgradePlanInformation: UpgradePlanInformation;
    CouponCode?: string;
  },
  setordersummarypage: (value: boolean) => void,
  { plan, tax, totalAmount }: { plan: PlanType[]; tax: number; totalAmount: number },
  groupId: string,
  groupName: string,
  planFailedCallback: () => void,
  refreshAuth0Token: () => void,
  setSubmitting: (isSubmitting: boolean) => void,
  isAnalyticsEnabled: boolean,
  couponDiscount: number,
  isDowngrade?: boolean
): AppThunk => {
  dataLayerPaymentEvent({
    plan,
    isAnalyticsEnabled,
    tax,
    paymentType: CREDIT_CARD,
    eventType: PURCHASE_ATTEMPT,
    couponCode: formData.CouponCode,
    couponDiscount,
    isDowngrade
  });
  const upgradePlanInfo = formData.UpgradePlanInformation;
  const newPrice = parseInt(formData.UpgradePlanInformation.NewPlanAmount);
  const oldPrice = parseInt(formData.UpgradePlanInformation.OldPrice);
  return async (dispatch: AppDispatch) => {
    const url = `${API_URLS.ChangeSubscription}`;
    const staticHeader = createHeaderForStoried();

    setSubmitting(false);
    dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.REQUEST));
    const errorMessage = "You already have a subscription to this plan.";

    try {
      const res = await callApi("POST", url, upgradePlanInfo, false, staticHeader);
      if (res?.data?.success) {
        const messages = res.data.messages;
        const { recurlySubId, recurlyTransId } = getrecurring(messages);

        if (recurlySubId !== "" || recurlyTransId !== "") {
          paymentSuccessNewSubs(true);
          const subData = {
            subscriptionId: recurlySubId,
            planId: formData.UpgradePlanInformation.NewPlanId,
            startDate: new Date()
          };
          subscriptionInfoFunc(subData);

          if (formData?.UserInformation?.EmailAddress && plan?.[0] && newPrice > oldPrice) {
            subscriptionUpdateTriggerEmail(formData, tax, totalAmount);
          }
        }

        dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.SUCCESS, upgradePlanInfo));

        if (groupId) {
          await dispatch(claimSponsorshipApi(groupId, groupName)); // Awaiting the promise
        }

        setTimeout(async () => {
          await Promise.all([refreshAuth0Token()]);

          if (!isDowngrade) {
            dataLayerPaymentEvent({
              plan,
              isAnalyticsEnabled,
              recurlySubscriptionId: recurlySubId,
              transactionId: recurlyTransId,
              tax,
              paymentType: CREDIT_CARD,
              eventType: PURCHASE,
              couponCode: formData.CouponCode,
              couponDiscount
            });
          }
        }, 10000);
      } else {
        setordersummarypage(false);
        dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.FAILURE, res?.data?.errorMessage));
        dispatch(addMessage(strFirstUpCase(res?.data?.errors?.[0] || errorMessage), "error"));
        groupId && planFailedCallback();

        if (!isDowngrade) {
          dataLayerPaymentEvent({
            plan,
            isAnalyticsEnabled,
            tax,
            failureReason: res?.data?.errors?.[0] || errorMessage,
            paymentType: CREDIT_CARD,
            eventType: PURCHASE_FAILURE,
            couponCode: formData.CouponCode,
            couponDiscount,
            isDowngrade
          });
        }
      }
    } catch (err: any) {
      setordersummarypage(false);
      dispatch(actionCreator(CONSTANTS.SUBMITCARDDETAILS.FAILURE, err));
      dispatch(
        addMessage(strFirstUpCase(err?.response?.data?.errors?.[0] || errorMessage), "error")
      );
      groupId && planFailedCallback();

      if (!isDowngrade) {
        dataLayerPaymentEvent({
          plan,
          isAnalyticsEnabled,
          tax,
          failureReason: err?.response?.data?.errors?.[0] || errorMessage,
          paymentType: CREDIT_CARD,
          eventType: PURCHASE_FAILURE,
          couponCode: formData.CouponCode,
          couponDiscount,
          isDowngrade
        });
      }
    }
  };
};

export const UpdatePaymentCard = (
  formData: Record<string, any>,
  setSubmitting: (isSubmitting: boolean) => void
): AppThunk<Promise<any | boolean>> => {
  return async (dispatch: AppDispatch) => {
    const requestData = { ...formData };
    const url = API_URLS.UpdatePaymentCard();
    const staticHeader = createHeaderForStoried();

    try {
      const res = await callApi("POST", url, requestData, false, staticHeader);
      setSubmitting(false);

      if (res.data.success) {
        return res.data;
      } else {
        let errorMsg =
          _.get(res, "data.errors[0]") ||
          _.get(res, "data.errorWithField[0]") ||
          "Sorry, your card is not added. Please try again.";
        errorMsg += "<br>Please note that we do not accept prepaid cards as a form of payment.";
        await dispatch(addMessage(errorMsg, "error"));
        return false;
      }
    } catch (err) {
      let errorMsg =
        _.get(err, "response.data.errors[0]") ||
        _.get(err, "response.data.errorWithField[0]") ||
        _.get(err, "response.data.errorMessage") ||
        "Sorry, your card is not added. Please try again.";
      errorMsg += "<br>Please note that we do not accept prepaid cards as a form of payment.";
      await dispatch(addMessage(errorMsg, "error"));
      setSubmitting(false);
      return false;
    }
  };
};

export const getStoriedBookPlans = async () => {
  try {
    const response = await fetch(
      `https://storiedteststorage.blob.core.windows.net/wastoriedgedcom/StoriedBookPlans.json`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch book plans: ${response.statusText}`);
    }
    const data = await response.json();
    setStoryBookStudioPlans(data);
  } catch (error) {
    console.error("Error fetching book plans:", error);
  }
};
